import { put, call, takeLatest } from "typed-redux-saga";
import { storage } from "../../utils/storage";
import { checkRefreshToken } from "../../api/frndly-api/user";
import { actions } from "../../state/actions-combinator";
import { setGapiTokenSaga } from "./set-gapi-token-saga";
import { logoutSaga } from "./logout-saga";

function* attemptRefreshToken() {
  try {
    const jwt = storage.getJsonWebToken();
    if (!jwt) {
      yield* put(actions.setIsAuthenticated(false));
      yield* put(actions.setIsLoadingAuth(false));
      return;
    }

    const { accessToken, accessTokenExpiryDate } = yield* call(
      checkRefreshToken,
      jwt
    );

    if (accessToken && accessTokenExpiryDate) {
      yield* put(
        actions.setGoogleApiAccessToken({
          accessToken,
          accessTokenExpiryDate,
        })
      );
    }
  } catch (error) {
    yield* put(actions.setIsAuthenticated(false));
    yield* put(actions.setIsLoadingAuth(false));
  }
}

function* authSagaWorker() {
  const { googleApiAccessToken, googleApiAccessTokenExpiryDate } =
    storage.getGoogleApiAccessToken();

  const isAccessTokenAndValid =
    googleApiAccessToken &&
    googleApiAccessTokenExpiryDate &&
    new Date().getTime() < Number(googleApiAccessTokenExpiryDate);

  if (isAccessTokenAndValid) {
    yield* put(
      actions.setGoogleApiAccessToken({
        accessToken: googleApiAccessToken,
        accessTokenExpiryDate: Number(googleApiAccessTokenExpiryDate),
      })
    );
  } else {
    // call api to get refresh token
    yield* call(attemptRefreshToken);
  }
}

export function* authSagaWatcher() {
  yield* put(actions.setIsLoadingAuth(true));
  yield* takeLatest(actions.setGoogleApiClientLoadSuccess, authSagaWorker);
  yield* takeLatest(actions.setGoogleApiAccessToken, setGapiTokenSaga);
  yield* takeLatest(actions.setLogout, logoutSaga);
}
