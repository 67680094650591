import { DatePicker } from "@mui/x-date-pickers";

type CustomDatePickerProps = {
  value: any;
  onChange: (value: any) => void;
};

export function CustomDatePicker({ value, onChange }: CustomDatePickerProps) {
  return <DatePicker value={value} onChange={onChange} />;
}
