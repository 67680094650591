import { ArrowLeftIcon } from "@radix-ui/react-icons";
import { Link } from "react-router-dom";

type TermsAndPrivacyHeaderProps = {
  title: string;
  link: string;
};

export default function TermsAndPrivacyHeader({
  title,
  link,
}: TermsAndPrivacyHeaderProps) {
  return (
    <div className="fixed top-0 left-0 w-full bg-gray-800 border-b">
      <div className="max-w-4xl mx-auto py-4 px-6 flex justify-between items-center">
        <Link to={link} className="px-4 py-4">
          <ArrowLeftIcon className="w-6 h-6" />
        </Link>
        <h1 className="text-4xl font-bold">{title}</h1>
        <p className="text-sm">Effective Date: June 14, 2024</p>
      </div>
    </div>
  );
}
