import { gapi } from "gapi-script";
import {
  FreeBusyRequestParams,
  FreeBusyResponse,
} from "../types/freebusy/query";
import {
  CalendarListRequestParams,
  CalendarListResponse,
} from "../types/calendar-list/list";
import {
  EventListResponse,
  EventsListRequestParams,
} from "../types/events/list";

export const googleApiWrapper = {
  calendar: {
    calendarList: {
      /**
       * Docs: https://developers.google.com/calendar/api/v3/reference/calendarList/list
       *
       * At least one of these scopes are required:
       * - https://www.googleapis.com/auth/calendar.readonly
       * - https://www.googleapis.com/auth/calendar
       */
      list: async function list(
        params?: CalendarListRequestParams
      ): Promise<CalendarListResponse> {
        return await gapi.client.calendar.calendarList.list(params);
      },
    },

    freebusy: {
      /**
       * Docs: https://developers.google.com/calendar/api/v3/reference/freebusy/query
       *
       * At least one of these scopes are required:
       * - https://www.googleapis.com/auth/calendar.readonly
       * - https://www.googleapis.com/auth/calendar
       */
      query: async function query(
        params?: FreeBusyRequestParams
      ): Promise<FreeBusyResponse> {
        return await gapi.client.calendar.freebusy.query(params);
      },
    },
    events: {
      /**
       *  Docs: https://developers.google.com/calendar/api/v3/reference/events/list
       *
       *  At least one of these scopes are required:
       * - https://www.googleapis.com/auth/calendar.readonly
       * - https://www.googleapis.com/auth/calendar
       * - https://www.googleapis.com/auth/calendar.events.readonly
       * - https://www.googleapis.com/auth/calendar.events
       */
      list: async function list(
        params?: EventsListRequestParams
      ): Promise<EventListResponse> {
        return await gapi.client.calendar.events.list(params);
      },
    },
  },
};
