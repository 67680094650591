import { gapi } from "gapi-script";
import { DISCOVERY_DOCS, SCOPES } from "../api/google/constants";
import { call, put } from "typed-redux-saga";
import { actions } from "../state/actions-combinator";

function loadGapiClient() {
  return new Promise((resolve) => {
    gapi.load("client:auth2", () => {
      resolve(true);
    });
  });
}

function initGapiClient() {
  return new Promise((resolve, reject) => {
    gapi.client
      .init({
        clientId: process.env.REACT_APP_CLIENT_ID,
        discoveryDocs: DISCOVERY_DOCS,
        scope: SCOPES,
      })
      .then(() => {
        resolve(true);
      })
      .catch((error: unknown) => {
        reject(error);
      });
  });
}

export function* googleApiClientSaga() {
  try {
    yield call(loadGapiClient);

    yield call(initGapiClient);

    yield put(actions.setGoogleApiClientLoadSuccess(true));
  } catch (error) {
    console.error("Error initializing Google API client:", error);
    yield put(actions.setGoogleClientLoadError(error as string));
  }
}
