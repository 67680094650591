import { appActions } from "./app-state";
import { authActions } from "./auth-state-slice";
import { googleApiActions } from "./google-api-state-slice";
import { googleClientActions } from "./google-client-state-slice";

export const actions = {
  ...appActions,
  ...authActions,
  ...googleClientActions,
  ...googleApiActions,
};
