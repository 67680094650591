import { configureStore } from "@reduxjs/toolkit";
import { AuthState, authSliceReducer } from "./auth-state-slice";
import createSagaMiddleware from "redux-saga";
import { GetDefaultMiddleware } from "@reduxjs/toolkit/dist/getDefaultMiddleware";
import { rootSaga } from "../sagas/rootSaga";
import {
  GoogleClientState,
  googleClientStateReducer,
} from "./google-client-state-slice";
import {
  GoogleApiState,
  googleApiStateReducer,
} from "./google-api-state-slice";
import { AppState, appReducer } from "./app-state";

const sagaMiddleware = createSagaMiddleware();

function createMiddleware(getDefaultMiddleware: GetDefaultMiddleware) {
  return getDefaultMiddleware({ thunk: false }).concat(sagaMiddleware);
}

type State = {
  app: AppState;
  auth: AuthState;
  googleClientState: GoogleClientState;
  googleApiState: GoogleApiState;
};

export const store = configureStore<State>({
  reducer: {
    app: appReducer,
    auth: authSliceReducer,
    googleClientState: googleClientStateReducer,
    googleApiState: googleApiStateReducer,
  },
  middleware: createMiddleware,
});

sagaMiddleware.run(rootSaga);

// Infer the `RootState` and `AppDispatch` types from the store itself

export type RootState = ReturnType<typeof store.getState>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
