import { createTheme } from "@mui/material/styles";
import { button, buttonHover, foreground } from "./colors";

const modalAndPopoverColor = "rgb(75 85 99)";

export const theme = createTheme({
  palette: {
    background: {
      default: "#1f2937", // bg-gray-800
      paper: "#374151", // A slightly lighter gray for modals/popovers
    },
    text: {
      primary: foreground, // text-gray-100
    },
    action: {
      active: foreground,
      hover: button,
    },
    secondary: {
      main: foreground,
    },
    // this will style the color of the selected day
    // primary: {
    //   main: foreground,
    // },
  },
  components: {
    // The border of the input field
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: foreground,
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#d1d5db",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: foreground,
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: foreground,
          fontColor: foreground,
        },
      },
    },
  },
});
