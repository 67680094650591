export function CalendarEventsEmptyState() {
  return (
    <div className="flex flex-col items-center justify-center h-full p-4">
      <h1 className="text-4xl font-bold mb-4 text-center text-gray-400">
        No Calendar Events available
      </h1>
      <p className="text-lg text-gray-400 text-center">
        Ensure you've selected a calendar and that you have events in your
        calendar.
      </p>
    </div>
  );
}
