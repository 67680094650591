import { takeLatest } from "typed-redux-saga";
import { actions } from "../../state/actions-combinator";
import { getCalendarEventsSaga } from "./calendar-events";
import {
  getCalendarListSaga,
  setCalendarListIsSelectedSaga,
} from "./calendar-list";

export function* googleApiWatcherSaga() {
  yield* takeLatest(actions.getCalendarList, getCalendarListSaga);
  yield* takeLatest(actions.getCalendarEvents, getCalendarEventsSaga);
  yield* takeLatest(
    actions.setCalendarListIsSelected,
    setCalendarListIsSelectedSaga
  );
}
