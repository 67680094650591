import { gapi } from "gapi-script";
import { select, put } from "typed-redux-saga";
import { RootState } from "../../state/store";
import { actions } from "../../state/actions-combinator";
import { storage } from "../../utils/storage";

export function* setGapiTokenSaga(): Generator {
  const googleApiAccessToken = yield* select(
    (state: RootState) => state.auth.googleApiAccessToken
  );

  if (
    googleApiAccessToken.accessToken &&
    googleApiAccessToken.accessTokenExpiryDate
  ) {
    gapi.client.setToken({
      access_token: googleApiAccessToken.accessToken,
    });
    storage.setAccessToken({
      token: googleApiAccessToken.accessToken,
      expiryDate: googleApiAccessToken.accessTokenExpiryDate.toString(),
    });
  }

  // put action to call calendarList

  yield put(actions.getCalendarList({ getCalendarEvents: true }));
}
