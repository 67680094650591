import Drawer from "@mui/material/Drawer";

import { useDispatch } from "react-redux";
import { appActions } from "../state/app-state";
import { CalendarSelector } from "./calendar-selector/calendar-selector";
import { useTypedSelector } from "../state/selectors";

export function SettingsDrawer() {
  const dispatch = useDispatch();
  const isSettingsDrawerOpen = useTypedSelector(
    (state) => state.app.isSettingsDrawerOpen
  );

  return (
    <Drawer
      open={isSettingsDrawerOpen}
      onClose={() => dispatch(appActions.setIsSettingsDrawerOpen(false))}
    >
      <div className="p-4 flex flex-col gap-4 bg-gray-800 flex-1">
        <CalendarSelector />
      </div>
    </Drawer>
  );
}
