import { CalendarList } from "../api/types/calendar-list/list";

const ACCESS_TOKEN_KEY = "accessToken";
const ACCESS_TOKEN_EXPIRY_DATE_KEY = "accessTokenExpiryDate";
const JSON_WEB_TOKEN_KEY = "jsonWebToken";
const CALENDAR_LIST_KEY = "calendarList";

export const storage = {
  setAccessToken: ({
    token,
    expiryDate,
  }: {
    token: string;
    expiryDate: string;
  }) => {
    localStorage.setItem(ACCESS_TOKEN_KEY, token);
    localStorage.setItem(ACCESS_TOKEN_EXPIRY_DATE_KEY, expiryDate);
  },
  getGoogleApiAccessToken: () => {
    return {
      googleApiAccessToken: localStorage.getItem(ACCESS_TOKEN_KEY),
      googleApiAccessTokenExpiryDate: localStorage.getItem(
        ACCESS_TOKEN_EXPIRY_DATE_KEY
      ),
    };
  },
  setJsonWebToken: (token: string) => {
    localStorage.setItem(JSON_WEB_TOKEN_KEY, token);
  },
  getJsonWebToken: () => {
    return localStorage.getItem(JSON_WEB_TOKEN_KEY);
  },
  clearAllTokensAndData: () => {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(ACCESS_TOKEN_EXPIRY_DATE_KEY);
    localStorage.removeItem(JSON_WEB_TOKEN_KEY);
    localStorage.removeItem(CALENDAR_LIST_KEY);
  },
  setCalendarList: (calendarList: CalendarList) => {
    localStorage.setItem(CALENDAR_LIST_KEY, JSON.stringify(calendarList));
  },

  getCalendarList: (): CalendarList | null => {
    const calendarList = localStorage.getItem(CALENDAR_LIST_KEY);
    try {
      return calendarList ? JSON.parse(calendarList) : null;
    } catch (error) {
      console.error("Error getting calendar list:", error);
      throw error;
    }
  },
};
