import { useCallback } from "react";
import { FallbackProps } from "react-error-boundary";
import { SUPPORT_EMAIL, APP_NAME } from "../constants";
import { Button } from "../components/button";

export function ErrorFallback({ error }: FallbackProps) {
  const onErrorTryAgain = useCallback(() => {
    const mailtoLink = `mailto:${SUPPORT_EMAIL}?subject=${APP_NAME}%20Error%20Report&body=${encodeURIComponent(
      error as string
    )}`;
    window.open(mailtoLink, "_blank");
  }, [error]);

  return (
    <div className="flex flex-col items-center justify-center h-full p-4 gap-1">
      <h1 className="text-4xl font-bold mb-4 text-center text-gray-400">
        We encountered an error
      </h1>
      <p className="text-lg text-gray-400 text-center">
        Please let us know about the error and refresh the page
      </p>
      <p className="text-lg text-gray-400 text-center">
        Sorry for the inconvenience!
      </p>

      <Button onClick={onErrorTryAgain} className="mt-2">
        Email Error
      </Button>
    </div>
  );
}
