import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export type AuthState = {
  isAuthenticated: boolean;
  isLoading: boolean;
  error: string | null;
  googleApiAccessToken: {
    accessToken: string | null;
    accessTokenExpiryDate: number | null;
  };
};

const initialState: AuthState = {
  isAuthenticated: false,
  isLoading: false,
  error: null,
  googleApiAccessToken: {
    accessToken: null,
    accessTokenExpiryDate: null,
  },
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setIsAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },
    setIsLoadingAuth: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setIsAuthError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
    setGoogleApiAccessToken: (
      state,
      action: PayloadAction<{
        accessToken: string | null;
        accessTokenExpiryDate: number | null;
      }>
    ) => {
      state.googleApiAccessToken = action.payload;
      state.isAuthenticated = true;
      state.isLoading = false;
    },
    setLogout: (state) => {
      state.isAuthenticated = false;
      state.isLoading = false;
      state.error = null;
      state.googleApiAccessToken = {
        accessToken: null,
        accessTokenExpiryDate: null,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const authActions = authSlice.actions;

export const authSliceReducer = authSlice.reducer;
