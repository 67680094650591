import clsx from "clsx";
import React from "react";
import { LoadingSpinner } from "./loading-spinner";

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  children?: React.ReactNode;
  isLoading?: boolean;
};

export function Button(props: ButtonProps) {
  const { className, children, isLoading, ...rest } = props;
  return (
    <button
      className={clsx(
        "bg-blue-500 text-white rounded-lg py-1 px-4 hover:opacity-50",
        className
      )}
      {...rest}
      disabled={isLoading}
    >
      {isLoading ? (
        <LoadingSpinner className="w-4 h-4 border border-white" />
      ) : (
        children
      )}
    </button>
  );
}
