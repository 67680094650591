import { DateAvailabilityList } from "../types";
import { CheckBoxRowProps, CheckboxRow } from "./checkbox-row";

type AvailabilityListProps = {
  availabilityList: DateAvailabilityList;
  toggleAvailability: CheckBoxRowProps["toggleAvailability"];
};

export function AvailabilityList(props: AvailabilityListProps) {
  const { availabilityList, toggleAvailability } = props;

  return (
    <div className="flex w-full flex-col overflow-auto">
      {availabilityList.map((date) => {
        return (
          <CheckboxRow
            dateAvailability={date}
            toggleAvailability={toggleAvailability}
            key={date.date.toString()}
          />
        );
      })}
    </div>
  );
}
