import * as Checkbox from "@radix-ui/react-checkbox";
import { CheckIcon, Cross2Icon } from "@radix-ui/react-icons";
import { DateAvailability } from "../types";
import clsx from "clsx";
import { useCallback } from "react";

export type CheckBoxRowProps = {
  dateAvailability: DateAvailability;
  toggleAvailability: (date: Date) => void;
};

export function CheckboxRow(props: CheckBoxRowProps) {
  const { dateAvailability, toggleAvailability } = props;

  const { date, isChecked, readableDate } = dateAvailability;

  const toggleAvailabilityLocal = useCallback(
    () => toggleAvailability(date),
    [date, toggleAvailability]
  );

  return (
    <div
      className="ps-2 flex justify-between items-center py-2 border-b select-none border-gray-700 hover:cursor-pointer focus:cursor-pointer"
      onClick={toggleAvailabilityLocal}
    >
      <label className=" text-[15px] leading-none text-gray-300" htmlFor="c1">
        {readableDate}
      </label>

      <Checkbox.Root
        className={clsx(
          "w-6 h-6 rounded-full flex items-center justify-center",
          isChecked ? "bg-green-500" : "bg-red-500"
        )}
        checked={isChecked}
      >
        {isChecked ? (
          <CheckIcon className="text-white" />
        ) : (
          <Cross2Icon className="text-white" />
        )}
      </Checkbox.Root>
    </div>
  );
}
