import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type GoogleClientState = {
  isLoading: boolean;
  error: string | null;
  successfullyLoaded: boolean;
};

const initialState: GoogleClientState = {
  isLoading: false,
  error: null,
  successfullyLoaded: false,
};

const googleClientState = createSlice({
  name: "googleClientState",
  initialState,
  reducers: {
    setGoogleClientLoadError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    setGoogleApiClientLoadSuccess: (state, action: PayloadAction<boolean>) => {
      state.successfullyLoaded = action.payload;
      state.isLoading = false;
    },
  },
});

export const googleClientActions = googleClientState.actions;

export const googleClientStateReducer = googleClientState.reducer;
