import { CalendarEvent } from "../api/types/events/list";
import { DateAvailability, DateAvailabilityList } from "../types";
import { formatDate } from "./format-date";

export function getAvailability(
  events: CalendarEvent[],
  currentDate: Date,
  endDate: Date
): DateAvailabilityList {
  const availabilityList: DateAvailabilityList = [];

  // Add one day to the endDate to include the endDate in the loop
  const endDatePlusOneDay = new Date(endDate);
  endDatePlusOneDay.setDate(endDatePlusOneDay.getDate() + 1);

  // Create a date object for iteration
  let iterationDate = new Date(currentDate);

  // Iterate through each date from currentDate to endDate
  while (iterationDate <= endDatePlusOneDay) {
    // Initialize date availability
    const dateAvailability: DateAvailability = {
      date: new Date(iterationDate),
      isFree: true,
      isChecked: true,
      events: [],
      readableDate: formatDate(iterationDate),
    };

    // Define 7 PM of the current iteration day
    const iterationDay7PM = new Date(iterationDate);
    iterationDay7PM.setHours(19, 0, 0, 0);

    // Iterate through events to check conditions
    for (const event of events) {
      if (!event.start.date && !event.start.dateTime) {
        // TODO: log error to remote logger
        throw new Error("start.dateTime and start.date are both undefined");
      }
      let eventStartDate;
      let eventEndDate;

      // All day event
      if (event.start.date && event.end.date) {
        eventStartDate = new Date(`${event.start.date}:00:00:00`);
        eventEndDate = new Date(`${event.end.date}:00:00:00`);
        // event with specific start and end times
      } else {
        eventStartDate = new Date(event.start.dateTime as string);
        eventEndDate = new Date(event.end.dateTime as string);
      }

      const endOfIterationDay = new Date(
        iterationDate.setHours(23, 59, 59, 999)
      );

      if (
        (eventStartDate >= iterationDay7PM &&
          eventStartDate <= endOfIterationDay) ||
        (eventEndDate >= iterationDay7PM &&
          eventEndDate <= endOfIterationDay) ||
        (eventStartDate <= iterationDay7PM && eventEndDate >= endOfIterationDay)
      ) {
        dateAvailability.isFree = false;
        dateAvailability.isChecked = false;
        dateAvailability.events?.push(event);
      }
    }

    availabilityList.push(dateAvailability);
    // increase iterationDate to run the loop again
    iterationDate.setDate(iterationDate.getDate() + 1);
  }

  return availabilityList;
}
