// src/api/userApi.ts
import axios from "axios";

const API_URL = `${process.env.REACT_APP_FAPI_URL}/api/v1/auth`;

// !not currently a route on the backend
// export const loginUser = async (refreshToken: string) => {
//   try {
//     const response = await axios.post(`${API_URL}/login-or-sign-up`, {
//       refreshToken,
//     });
//     return response.data;
//   } catch (error) {
//     console.error("Error logging in user:", error);
//     throw error;
//   }
// };

type RefreshTokenResponse = {
  accessToken?: string;
  accessTokenExpiryDate?: number;
  message?: string;
};

export const checkRefreshToken = async (
  jwt: string
): Promise<RefreshTokenResponse> => {
  try {
    const response = await axios.get(`${API_URL}/google/refresh-token`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error checking refresh token:", error);
    throw error;
  }
};

export type PostAuthCodeResponse = {
  jsonWebToken: string;
  accessToken: string;
  accessTokenExpiryDate: string;
};

export const postAuthCode = async (
  authCode: string
): Promise<PostAuthCodeResponse> => {
  try {
    const response = await axios.post(`${API_URL}/google`, { authCode });
    return response.data;
  } catch (error) {
    console.error("Error posting auth code:", error);
    throw error;
  }
};

export async function logout(jwt: string) {
  try {
    await axios.post(
      `${API_URL}/logout`,
      {},
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }
    );
  } catch (error) {
    console.error("Error logging out:", error);
    throw error;
  }
}
