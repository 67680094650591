import { useTypedSelector } from "../../state/selectors";
import { CalendarSelectorRow } from "./calendar-selector-row";

export function CalendarSelector() {
  const calendarList = useTypedSelector(
    (state) => state.googleApiState.calendarList.data?.items
  );

  return (
    <div className="flex flex-col gap-1">
      <div className="text-[15px] font-bold leading-none text-gray-100">
        My Calendars
      </div>
      {calendarList?.map((calendar) => (
        <CalendarSelectorRow
          key={calendar.id}
          name={calendar.summary}
          isSelected={calendar.selected}
          id={calendar.id}
        />
      ))}
    </div>
  );
}
