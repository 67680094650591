import { useCallback, useEffect, useMemo, useState } from "react";
import { getAvailability } from "../../utils/get-availability";
import { DateAvailabilityList } from "../../types";
import { AvailabilityList } from "../../components/availability-list";
import { ShareButton } from "../../components/share-button";
import { LoadingSpinner } from "../../components/loading-spinner";
import { Button } from "../../components/button";
import { Footer } from "../../components/footer";
import { AuthlessLandingPage } from "../../components/authless-landing-page";
import { APP_NAME, SUPPORT_EMAIL } from "../../constants";
import { StartEndDatePicker } from "../../components/start-end-date-picker";
import { getDefaultStartAndEndDates } from "../../utils/get-default-end-date";
import dayjs, { Dayjs } from "dayjs";
import { useGoogleLogin } from "@react-oauth/google";
import { postAuthCode } from "../../api/frndly-api/user";
import { storage } from "../../utils/storage";
import { SCOPES } from "../../api/google/constants";
import { useDispatch } from "react-redux";
import { actions } from "../../state/actions-combinator";
import { Header } from "../../components/header";
import { SettingsDrawer } from "../../components/settings-drawer";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallback } from "../error-fallback";
import { CalendarEventsEmptyState } from "../../components/calendar-events-empty-state";
import { useTypedSelector } from "../../state/selectors";

export function Calendar() {
  const dispatch = useDispatch();
  const { data: calendarEvents, isLoading: isLoadingCalendarEvents } =
    useTypedSelector((state) => state.googleApiState.calendarEvents);
  const isAuthenticated = useTypedSelector(
    (state) => state.auth.isAuthenticated
  );
  const isLoadingAuth = useTypedSelector((state) => state.auth.isLoading);

  const isLoading = isLoadingAuth || isLoadingCalendarEvents;

  const [availability, setAvailability] = useState<DateAvailabilityList>([]);

  const [isError, setIsError] = useState<string | null>(null);

  const { defaultStartDate, defaultEndDate } = useMemo(
    () => getDefaultStartAndEndDates(),
    []
  );

  // the date picker package expects an object with startDate and endDate properties, even if only a single date is selected by the picker
  const [startDate, setStartDate] = useState<Dayjs>(dayjs(defaultStartDate));

  const [endDate, setEndDate] = useState<Dayjs>(dayjs(defaultEndDate));

  const getUpcomingAvailability = useCallback(() => {
    if (!calendarEvents || !calendarEvents.length) {
      console.log("No calendarEvents defined in getUpcomingAvailability");
      return;
    }

    // TODO: fix types
    const availabilityResponse = getAvailability(
      calendarEvents,
      startDate.toDate(),
      endDate.toDate()
      // startDate.startDate as Date,
      // endDate.endDate as Date
    );

    setAvailability(availabilityResponse);
  }, [calendarEvents, startDate, endDate]);

  useEffect(() => {
    if (!calendarEvents) return;
    getUpcomingAvailability();
  }, [calendarEvents, endDate, getUpcomingAvailability, startDate]);

  const toggleAvailability = (date: Date) => {
    setAvailability((prevState) =>
      prevState.map((item) =>
        item.date === date ? { ...item, isChecked: !item.isChecked } : item
      )
    );
  };

  /**
   * When flow = auth-code, this is calling  window?.google?.accounts?.oauth2.initCodeClient
   *
   * It gets my client-id from the provider in App.tsx
   *
   */
  const googleLogin = useGoogleLogin({
    flow: "auth-code",
    scope: SCOPES,
    onSuccess: async (codeResponse) => {
      const { accessToken, accessTokenExpiryDate, jsonWebToken } =
        await postAuthCode(codeResponse.code);

      storage.setAccessToken({
        token: accessToken,
        expiryDate: accessTokenExpiryDate,
      });
      // TODO: store in http cookie
      // https://developer.mozilla.org/en-US/docs/Web/HTTP/Cookies
      storage.setJsonWebToken(jsonWebToken);

      dispatch(
        actions.setGoogleApiAccessToken({
          accessToken,
          accessTokenExpiryDate: Number(accessTokenExpiryDate),
        })
      );
    },
    onError: (errorResponse) => console.log(errorResponse),
  });

  const onLoginOrLogout = useCallback(() => {
    if (isAuthenticated) {
      dispatch(actions.setLogout());
      return;
    }

    googleLogin();
  }, [dispatch, googleLogin, isAuthenticated]);

  const onErrorTryAgain = useCallback(() => {
    const mailtoLink = `mailto:${SUPPORT_EMAIL}?subject=${APP_NAME}%20Error%20Report&body=${encodeURIComponent(
      isError as string
    )}`;
    window.open(mailtoLink, "_blank");
    setIsError(null);
    getUpcomingAvailability();
  }, [getUpcomingAvailability, isError]);

  const handleStartDateChanged = (newValue: any) => {
    setStartDate(newValue);
  };

  const handleEndDateChanged = (newValue: any) => {
    setEndDate(newValue);
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <div className="flex flex-col w-full h-full p-8 gap-4">
        <SettingsDrawer />
        <Header
          isAuthenticated={isAuthenticated}
          isLoading={isLoading}
          onLoginOrLogout={onLoginOrLogout}
        />

        {(() => {
          if (isError) {
            return (
              <div className="flex justify-center flex-col gap-4 items-center h-screen">
                <h2 className="text-gray-100 text-2xl text-center">Error</h2>
                <p className="text-gray-100 text-md text-center">{isError}</p>
                <Button onClick={onErrorTryAgain}>
                  Try again & Email Error
                </Button>
              </div>
            );
          }

          if (isLoading) {
            return (
              <div className="flex justify-center items-center h-screen">
                <LoadingSpinner />
              </div>
            );
          }

          if (isAuthenticated) {
            if (!calendarEvents || !calendarEvents.length) {
              return <CalendarEventsEmptyState />;
            }
            return (
              <>
                <StartEndDatePicker
                  startDate={startDate}
                  endDate={endDate}
                  setStartDate={handleStartDateChanged}
                  setEndDate={handleEndDateChanged}
                />

                <AvailabilityList
                  availabilityList={availability}
                  toggleAvailability={toggleAvailability}
                />

                <ShareButton availability={availability} />
              </>
            );
          }

          return <AuthlessLandingPage />;
        })()}
        <Footer />
      </div>
    </ErrorBoundary>
  );
}
