import React from "react";
import { Button } from "./button";
import { ReactComponent as FrndlyLogo } from "../assets/icons/frndly-logo-v2.svg";
import { APP_NAME } from "../constants";
import { HamburgerMenuIcon } from "@radix-ui/react-icons";
import { useDispatch } from "react-redux";
import { actions } from "../state/actions-combinator";

type HeaderProps = {
  isAuthenticated: boolean;
  isLoading: boolean;
  onLoginOrLogout: () => void;
};

export function Header({
  isAuthenticated,
  isLoading,
  onLoginOrLogout,
}: HeaderProps) {
  const dispatch = useDispatch();

  const openSettingsDrawer = () => {
    dispatch(actions.setIsSettingsDrawerOpen(true));
  };

  return (
    <div className="flex justify-between gap-6 items-center">
      <button onClick={openSettingsDrawer}>
        <HamburgerMenuIcon className="w-[30px] h-[30px] text-white" />
      </button>
      <div className="flex gap-2 items-center">
        <FrndlyLogo className="w-[30px] h-[30px]" />
        <h1 className="text-3xl font-bold text-center text-gray-100">
          {APP_NAME}
        </h1>
      </div>
      {!isLoading && (
        <Button onClick={onLoginOrLogout} className="h-[30px]">
          {isAuthenticated ? "Logout" : "Login with Google"}
        </Button>
      )}
    </div>
  );
}
