import { call } from "typed-redux-saga";
import { storage } from "../../utils/storage";
import { logout as apiLogout } from "../../api/frndly-api/user";

export function* logoutSaga() {
  try {
    const jwt = storage.getJsonWebToken();
    if (jwt) {
      yield* call(apiLogout, jwt);
    }
  } catch (error) {
    console.error("Error during logout saga: ", error);
  } finally {
    storage.clearAllTokensAndData();
  }
}
