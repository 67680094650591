import clsx from "clsx";

type LoadingSpinnerProps = {
  className?: string;
};

export function LoadingSpinner(props: LoadingSpinnerProps) {
  const { className } = props;
  return (
    <div
      className={clsx(
        "w-16 h-16 border-4 border-blue-500 border-t-transparent border-solid rounded-full animate-spin",
        className
      )}
    />
  );
}
