import { Link } from "react-router-dom";
import { SUPPORT_EMAIL } from "../constants";

export function Footer() {
  return (
    <footer className="w-full p-4 text-left text-white/50 flex flex-col gap-4">
      <div className="flex gap-4 text-xs md:text-base">
        <Link to="/privacy">Privacy Policy</Link>
        <Link to="/terms">Terms of Service</Link>
        <div>
          Support:{" "}
          <a
            href={`mailto:${SUPPORT_EMAIL}`}
            className="text-white/50 hover:underline"
          >
            {SUPPORT_EMAIL}
          </a>
        </div>
      </div>
    </footer>
  );
}
