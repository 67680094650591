import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Privacy from "./screens/privacy";
import { Terms } from "./screens/terms";
import { Toaster } from "sonner";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./theme/mui-theme";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Provider } from "react-redux";
import { store } from "./state/store";
import { CalendarWithErrorBoundary } from "./screens/calendar/calendar-with-error-boundary";

const router = createBrowserRouter([
  {
    path: "/",
    element: <CalendarWithErrorBoundary />,
  },
  {
    path: "/privacy",
    element: <Privacy />,
  },
  {
    path: "/terms",
    element: <Terms />,
  },
]);

export function App() {
  return (
    <Provider store={store}>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID as string}>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Toaster theme="dark" position="top-center" />
            <RouterProvider router={router} />
          </LocalizationProvider>
        </ThemeProvider>
      </GoogleOAuthProvider>
    </Provider>
  );
}
