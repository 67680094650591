import { googleApiWrapper } from "../../api/google/google-calendar-api";
import { actions } from "../../state/actions-combinator";
import { call, put, select } from "typed-redux-saga";
import { storage } from "../../utils/storage";
import {
  CalendarList,
  CalendarListEntry,
} from "../../api/types/calendar-list/list";
import { RootState } from "../../state/store";

export function* getCalendarListSaga({
  payload,
}: {
  payload: { getCalendarEvents: boolean };
}) {
  try {
    const { result: calendarList } = yield* call(
      googleApiWrapper.calendar.calendarList.list
    );

    let calendarListToUse = calendarList;

    const calendarListFromStorage = storage.getCalendarList();

    const persistedCalendarListItems: Record<string, CalendarListEntry> = {};
    let calendarListWithUserSelected: CalendarList;

    if (calendarListFromStorage && calendarListFromStorage.items.length) {
      for (const calendar of calendarListFromStorage.items) {
        persistedCalendarListItems[calendar.id] = calendar;
      }
      calendarListWithUserSelected = {
        ...calendarList,
        items: calendarList.items.map((calendar) => ({
          ...calendar,
          selected: persistedCalendarListItems[calendar.id]?.selected,
        })),
      };
      calendarListToUse = calendarListWithUserSelected;
    }

    storage.setCalendarList(calendarListToUse);

    yield* put(actions.setCalendarList(calendarListToUse));

    if (payload.getCalendarEvents) {
      yield* put(actions.getCalendarEvents());
    }
  } catch (error) {
    console.error("BBenji  error", error);
    yield* put(actions.setCalendarListError(error));
  }
}

export function* setCalendarListIsSelectedSaga() {
  try {
    const calendarList = yield* select(
      (state: RootState) => state.googleApiState.calendarList.data
    );

    if (calendarList) {
      storage.setCalendarList(calendarList);
      yield* put(actions.getCalendarEvents());
    }
  } catch (error) {
    console.log("BBenji  error", error);
  }
}
