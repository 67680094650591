import * as Checkbox from "@radix-ui/react-checkbox";
import { CheckIcon } from "@radix-ui/react-icons";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import { actions } from "../../state/actions-combinator";

type CalendarSelectorRowProps = {
  name: string;
  isSelected: boolean;
  id: string;
};

export function CalendarSelectorRow({
  name,
  isSelected,
  id,
}: CalendarSelectorRowProps) {
  const dispatch = useDispatch();
  const onClick = () => {
    dispatch(
      actions.setCalendarListIsSelected({
        calendarId: id,
        isSelected: !isSelected,
      })
    );
  };

  return (
    <div
      className="ps-2 flex justify-between items-center py-2 border-b select-none gap-4 border-gray-700 hover:cursor-pointer focus:cursor-pointer"
      onClick={onClick}
    >
      <label className=" text-[15px] leading-none text-gray-300" htmlFor="c1">
        {name}
      </label>
      <Checkbox.Root
        className={clsx(
          "w-6 h-6 rounded-full flex items-center justify-center",
          isSelected ? "bg-green-500" : "border border-gray-300"
        )}
        checked={isSelected}
      >
        {isSelected && <CheckIcon className="text-white" />}
      </Checkbox.Root>
    </div>
  );
}
