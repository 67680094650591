import TermsAndPrivacyHeader from "../components/terms-and-privacy-header";
import { APP_NAME } from "../constants";

export function Terms() {
  return (
    <div className="max-w-4xl mx-auto py-12 px-6 text-gray-100">
      <TermsAndPrivacyHeader title="Terms of Service" link="/" />

      <div className="max-w-4xl mx-auto py-24 px-6">
        <h2 className="text-2xl font-semibold mb-4">1. Introduction</h2>
        <p className="mb-4">
          Welcome to {APP_NAME}! By using our app, you agree to these Terms of
          Service. Please read them carefully.
        </p>

        <h2 className="text-2xl font-semibold mb-4">2. Use of Our Service</h2>
        <p className="mb-4">
          You agree to use {APP_NAME} in compliance with all applicable laws and
          not for any unlawful or prohibited purpose. You must not use our
          service to:
        </p>
        <ul className="list-disc pl-6 mb-4">
          <li className="mb-2">Distribute harmful or inappropriate content</li>
          <li className="mb-2">
            Engage in any activity that interferes with or disrupts the service
          </li>
          <li className="mb-2">
            Attempt to gain unauthorized access to our systems or user accounts
          </li>
        </ul>

        <h2 className="text-2xl font-semibold mb-4">3. User Accounts</h2>
        <p className="mb-4">
          You are responsible for maintaining the confidentiality of your
          account information and for all activities that occur under your
          account. You agree to notify us immediately of any unauthorized use of
          your account.
        </p>

        <h2 className="text-2xl font-semibold mb-4">4. Privacy</h2>
        <p className="mb-4">
          Your use of {APP_NAME} is also governed by our Privacy Policy, which
          explains how we collect, use, and protect your information. By using
          our service, you consent to our collection and use of your information
          as described in the Privacy Policy.
        </p>

        <h2 className="text-2xl font-semibold mb-4">
          5. Intellectual Property
        </h2>
        <p className="mb-4">
          All content and materials on {APP_NAME}, including text, graphics,
          logos, and software, are the property of {APP_NAME} or its licensors
          and are protected by intellectual property laws. You agree not to use,
          reproduce, or distribute any content from our service without our
          permission.
        </p>

        <h2 className="text-2xl font-semibold mb-4">6. Termination</h2>
        <p className="mb-4">
          We reserve the right to terminate or suspend your access to {APP_NAME}{" "}
          at any time, without notice, for conduct that we believe violates
          these Terms of Service or is harmful to other users of the service,
          us, or third parties, or for any other reason.
        </p>

        <h2 className="text-2xl font-semibold mb-4">
          7. Disclaimer of Warranties
        </h2>
        <p className="mb-4">
          {APP_NAME} is provided "as is" and "as available" without any
          warranties of any kind, either express or implied. We do not warrant
          that the service will be uninterrupted or error-free, and we are not
          responsible for any loss or damage arising from your use of the
          service.
        </p>

        <h2 className="text-2xl font-semibold mb-4">
          8. Limitation of Liability
        </h2>
        <p className="mb-4">
          In no event will {APP_NAME}, its affiliates, or their respective
          directors, officers, employees, or agents be liable for any indirect,
          incidental, special, consequential, or punitive damages arising out of
          or related to your use of the service.
        </p>

        <h2 className="text-2xl font-semibold mb-4">
          9. Changes to These Terms
        </h2>
        <p className="mb-4">
          We may update these Terms of Service from time to time. We will notify
          you of any changes by posting the new Terms of Service on our website
          and app. You are advised to review these Terms periodically for any
          changes.
        </p>

        <h2 className="text-2xl font-semibold mb-4">10. Age Requirements</h2>
        <p className="mb-4">
          You are not permitted to use {APP_NAME} if you are less than 18 years
          old.
        </p>

        <h2 className="text-2xl font-semibold mb-4">11. Contact Us</h2>
        <p className="mb-4">
          If you have any questions or concerns about these Terms of Service,
          please contact us at:
        </p>
        <p className="mb-4">Email: bzlight2012@gmail.com</p>

        <p>By using {APP_NAME}, you agree to these Terms of Service.</p>
      </div>
    </div>
  );
}
