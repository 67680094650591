import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CalendarList } from "../api/types/calendar-list/list";
import { CalendarEvent } from "../api/types/events/list";

export type GoogleApiState = {
  calendarList: {
    isLoading: boolean;
    error: unknown | null;
    successfullyLoaded: boolean;
    data: CalendarList | null;
  };
  calendarEvents: {
    isLoading: boolean;
    error: unknown | null;
    successfullyLoaded: boolean;
    data: CalendarEvent[] | null;
  };
};

const initialState: GoogleApiState = {
  calendarList: {
    isLoading: false,
    error: null,
    successfullyLoaded: false,
    data: null,
  },
  calendarEvents: {
    isLoading: false,
    error: null,
    successfullyLoaded: false,
    data: null,
  },
};

type SetCalendarListIsSelectedAction = {
  isSelected: boolean;
  calendarId: string;
};

const googleApiState = createSlice({
  name: "googleClientState",
  initialState,
  reducers: {
    // Calendar List
    getCalendarList: (
      state,
      _action: PayloadAction<{ getCalendarEvents: boolean }>
    ) => {
      state.calendarList.isLoading = true;
    },
    setCalendarList: (state, action: PayloadAction<CalendarList>) => {
      state.calendarList.data = action.payload;
      state.calendarList.isLoading = false;
    },
    setCalendarListError: (state, action: PayloadAction<unknown>) => {
      state.calendarList.error = action.payload;
      state.calendarList.isLoading = false;
    },
    setCalendarListIsSelected: (
      state,
      action: PayloadAction<SetCalendarListIsSelectedAction>
    ) => {
      console.log("BBenji  reducer ran");
      state.calendarList.data?.items.forEach((calendar) => {
        calendar.selected =
          calendar.id === action.payload.calendarId
            ? action.payload.isSelected
            : calendar.selected;
      });
    },

    // Calendar Events
    getCalendarEvents: (state) => {
      state.calendarEvents.isLoading = true;
    },
    setCalendarEvents: (state, action: PayloadAction<CalendarEvent[]>) => {
      state.calendarEvents.data = action.payload;
      state.calendarEvents.isLoading = false;
    },
    setCalendarEventsError: (state, action: PayloadAction<unknown>) => {
      state.calendarEvents.error = action.payload;
      state.calendarEvents.isLoading = false;
    },
  },
});

export const googleApiActions = googleApiState.actions;

export const googleApiStateReducer = googleApiState.reducer;
