import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallback } from "../error-fallback";
import { Calendar } from "./calendar";

export function CalendarWithErrorBoundary() {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Calendar />
    </ErrorBoundary>
  );
}
