import TermsAndPrivacyHeader from "../components/terms-and-privacy-header";
import { APP_NAME, SUPPORT_EMAIL } from "../constants";

export default function Privacy() {
  return (
    <div className="text-gray-100">
      <TermsAndPrivacyHeader title="Privacy Policy" link="/" />
      <div className="max-w-4xl mx-auto py-24 px-6">
        <div className="p-6 rounded-lg shadow-md">
          <h2 className="text-2xl font-semibold mb-4">1. Introduction</h2>
          <p className="mb-4">
            Welcome to {APP_NAME}! We are committed to protecting your privacy
            and ensuring the security of your personal information. This Privacy
            Policy explains how we collect, use, and share your information when
            you use our app and services.
          </p>

          <h2 className="text-2xl font-semibold mb-4">
            2. Information We Collect
          </h2>
          <ul className="list-disc pl-6 mb-4">
            <li className="mb-2">
              <strong>Personal Information</strong>: When you sign up for
              {APP_NAME}, we collect your name, email address, and any other
              information you provide.
            </li>
            <li className="mb-2">
              <strong>Google Calendar Data</strong>: With your permission, we
              access your Google Calendar data to sync your availability. We
              only access the information necessary to provide our services.
            </li>
            <li className="mb-2">
              <strong>Usage Data</strong>: We collect information about how you
              use our app, including the features you use and the time and
              duration of your activities.
            </li>
          </ul>

          <h2 className="text-2xl font-semibold mb-4">
            3. How We Use Your Information
          </h2>

          <p className="mb-4">
            We use your Google Calendar data solely to display your availability
            within our app. No one at our company will access, read, or share
            your calendar data with any third parties.
          </p>
          <p className="mb-4">
            We use the information we collect about how you use the app
            (analytics) to:
          </p>
          <ul className="list-disc pl-6 mb-4">
            <li className="mb-2">Personalize your experience within the app</li>
            <li className="mb-2">
              Communicate with you, including sending updates and notifications
            </li>
            <li className="mb-2">
              Analyze usage and trends to improve our services
            </li>
            <li className="mb-2">Ensure the security of our services</li>
          </ul>

          <h2 className="text-2xl font-semibold mb-4">
            4. Sharing Your Information
          </h2>
          <p className="mb-4">
            We do not sell or rent your personal information to third parties.
            We may share your information with:
          </p>
          <ul className="list-disc pl-6 mb-4">
            <li className="mb-2">
              <strong>Service Providers</strong>: Third-party vendors who help
              us provide and improve our services.
            </li>
            <li className="mb-2">
              <strong>Legal Requirements</strong>: When required by law or to
              protect our rights and users.
            </li>
          </ul>

          <h2 className="text-2xl font-semibold mb-4">5. Data Security</h2>
          <p className="mb-4">
            We implement appropriate security measures to protect your
            information from unauthorized access, alteration, disclosure, or
            destruction. However, no method of transmission over the Internet or
            electronic storage is 100% secure.
          </p>

          <h2 className="text-2xl font-semibold mb-4">6. Children's Privacy</h2>
          <p className="mb-4">
            {APP_NAME} is not intended for use by individuals under the age of
            18. We do not knowingly collect personal information from children
            under 18. If we become aware that a child under 18 has provided us
            with personal information, we will take steps to delete such
            information.
          </p>

          <h2 className="text-2xl font-semibold mb-4">
            7. Compliance with Google API Services User Data Policy
          </h2>
          <p className="mb-4">
            Our use of information received from Google APIs will adhere to the
            Google API Services User Data Policy, including the Limited Use
            requirements. For more details, please visit{" "}
            <a
              href="https://developers.google.com/terms/api-services-user-data-policy"
              className="text-blue-400 hover:underline"
            >
              Google API Services User Data Policy
            </a>
            .
          </p>

          <h2 className="text-2xl font-semibold mb-4">
            8. Changes to This Privacy Policy
          </h2>
          <p className="mb-4">
            We may update this Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on our website
            and app. You are advised to review this Privacy Policy periodically
            for any changes.
          </p>

          <h2 className="text-2xl font-semibold mb-4">9. Contact Us</h2>
          <p className="mb-4">
            If you have any questions or concerns about this Privacy Policy,
            please contact us at:
          </p>
          <p className="mb-4">Email: {SUPPORT_EMAIL}</p>

          <p>
            By using {APP_NAME}, you agree to the terms of this Privacy Policy.
          </p>
        </div>
      </div>
    </div>
  );
}
