import { toast } from "sonner";
import { APP_NAME, WEB_URL } from "../constants";
import { DateAvailabilityList } from "../types";

type ShareButtonProps = {
  availability: DateAvailabilityList;
};

export function ShareButton(props: ShareButtonProps) {
  const { availability } = props;

  const getAvailabilityText = () => {
    let availableDates = "";

    for (const date of availability) {
      if (!date.isChecked) continue;
      availableDates = availableDates + "\n• " + date.readableDate;
    }

    return `I'm available on the following dates: \n${availableDates} \n\nAuto generated by ${APP_NAME}: ${WEB_URL}.`;
  };

  const shareAvailability = () => {
    const availabilityText = getAvailabilityText();

    if (navigator.share) {
      navigator.share({
        text: availabilityText,
      });
    } else {
      navigator.clipboard.writeText(availabilityText);
      toast.success("Copied to clipboard");
    }
  };

  return (
    <button
      className="w-full mt-4 py-2 select-none bg-blue-500 text-white rounded hover:bg-blue-600"
      onClick={shareAvailability}
    >
      Share Availability
    </button>
  );
}
