import { CustomDatePicker } from "./custom-date-picker";

type StartEndDatePickerProps = {
  startDate: any;
  endDate: any;
  setStartDate: (date: any) => void;
  setEndDate: (date: any) => void;
};

export function StartEndDatePicker({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}: StartEndDatePickerProps) {
  return (
    <div className="flex justify-center gap-4 md:gap-10">
      <CustomDatePicker value={startDate} onChange={setStartDate} />
      <CustomDatePicker value={endDate} onChange={setEndDate} />
    </div>
  );
}
