import { APP_NAME } from "../constants";

export function AuthlessLandingPage() {
  return (
    <div className="bg-gray-800 text-gray-100 p-4">
      <header className="text-center mb-12">
        <h1 className="text-4xl font-bold">
          Simplify Plans with Friends & Family
        </h1>
      </header>

      <main className="max-w-4xl mx-auto">
        <section className="mb-8">
          <ul className="list-disc list-inside text-lg">
            <li>
              Easily share your free time with friends by syncing with your
              Google Calendar.
            </li>
            <li>Share your availability with on click in any messaging app</li>
            <li>Automatic syncing with Google Calendar</li>
            <li>
              Avoid being socially awkward by sending your friends a link to
              book time in your calendar 🫣
            </li>
          </ul>
        </section>
        <div className="text-xs text-white/50 max-w-[800px]">
          {APP_NAME} only reads your Google Calendar events to determine your
          free time. We do not store or share your calendar data with any third
          parties. All of your data is processed on your device and not sent to
          us.
        </div>
      </main>
    </div>
  );
}
