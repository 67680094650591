import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export type AppState = {
  isSettingsDrawerOpen: boolean;
};

const initialState: AppState = {
  isSettingsDrawerOpen: false,
};

export const appState = createSlice({
  name: "app",
  initialState,
  reducers: {
    setIsSettingsDrawerOpen: (state, action: PayloadAction<boolean>) => {
      state.isSettingsDrawerOpen = action.payload;
    },
  },
});

export const appActions = appState.actions;
export const appReducer = appState.reducer;
