import { googleApiWrapper } from "../../api/google/google-calendar-api";
import {
  CalendarEvent,
  EventsListRequestParams,
} from "../../api/types/events/list";
import { actions } from "../../state/actions-combinator";
import { put, call, select } from "typed-redux-saga";
import { RootState } from "../../state/store";

export function* getCalendarEventsSaga() {
  try {
    const calendarList = yield* select(
      (state: RootState) => state.googleApiState.calendarList.data
    );

    if (!calendarList) return;

    const calendarIds: { id: string }[] = [];

    for (const item of calendarList.items) {
      if (item.selected) {
        calendarIds.push({ id: item.id });
      }
    }

    if (!calendarIds || calendarIds?.length === 0) {
      console.warn("Bbenji No calendar IDs found, defaulting to primary");
      calendarIds.push({ id: "primary" });
    }
    const events: CalendarEvent[] = [];
    for (const calendarId of calendarIds) {
      const eventListArgs: EventsListRequestParams = {
        calendarId: calendarId.id,
        timeMin: new Date().toISOString(),
        showDeleted: false,
        singleEvents: true,
        maxResults: 500,
        orderBy: "startTime",
      };

      const calendarEvents = yield* call(
        googleApiWrapper.calendar.events.list,
        eventListArgs
      );

      events.push(...calendarEvents.result.items);
    }

    yield put(actions.setCalendarEvents(events));
  } catch (error) {
    console.error("BBenji  error", error);
    yield put(actions.setCalendarEventsError(error));
  }
}
