export function getDefaultStartAndEndDates() {
  const currentDateAndTime = new Date();

  const tomorrowAt7Pm = new Date(currentDateAndTime);
  tomorrowAt7Pm.setDate(currentDateAndTime.getDate() + 1);
  tomorrowAt7Pm.setHours(19, 0, 0, 0);

  const twoWeeksFromNow = new Date();
  twoWeeksFromNow.setDate(currentDateAndTime.getDate() + 14);
  twoWeeksFromNow.setHours(19, 0, 0, 0);
  // Set the time to 11:59 PM
  twoWeeksFromNow.setHours(23, 59, 0, 0);

  return {
    defaultStartDate: currentDateAndTime,
    defaultEndDate: twoWeeksFromNow,
  };
}
